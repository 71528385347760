<template>
  <div class="back">
    <div class="back_inner" :style="{'width':winWidth+'px'}">
      <div class="top_white" :style="{'width':winWidth+'px'}">
        <div class="top_back" @click="go_back()">
          <van-image :src="left_img" width="9" height="15" :show-loading="false" />
          <span style="margin-left:10px">地址管理</span>
        </div>
      </div>
      <div style="height:1.5rem"></div>
      <div v-for="(item,index) in address_info" :key="index" class="address_slot">
        <van-swipe-cell>
          <div class="address_left">
            <div class="address_first" @click="selectAddr(item)">
              <div class="first_name">{{item.name}}</div>
              <div class="second_phone">{{item.phone}}</div>
              <div class="default" v-if="item.default_status">默认</div>
            </div>
            <div class="address_second">
              <div
                class="add_des"
                @click="selectAddr(item)"
              >{{item.province+item.city+item.area+item.description}}</div>
              <div class="edit_div" @click="go_add(item)">
                <van-icon name="edit" />
              </div>
            </div>
          </div>
          <template #right>
            <van-button
              @click="deleteIt(item.id,index)"
              square
              text="删除"
              type="danger"
              class="delete-button"
            />
          </template>
        </van-swipe-cell>
      </div>
      <div class="go_it_outer" :style="{'width':winWidth+'px'}">
        <div class="go_it" @click="go_add()">添加新地址</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      left_img: "http://qiniupc.stylika.online/upload_pic_1615452264549_h=18",
      winWidth: 0,
      address_info: []
    };
  },
  mounted() {
    this.winWidth = document.body.clientWidth;
    this.getData();
  },
  methods: {
    selectAddr(val) {
    this.$store.state.userAddressData = val
    this.$router.go(-1);
    },
    go_back() {
      this.$router.go(-1);
    },
    getData() {
      this.$iHttp
        .get("/api/shopping_car/user_address/?all=1")
        .then(res => {
          res.data.data.address_info.map(item => {
            item.address =
              item.province + item.city + item.area + item.description;
            item.show = false;
          });
          this.address_info = res.data.data.address_info;
        })
        .catch(err => {
          console.log(err);
        });
    },
    deleteIt(id, idx) {
      this.address_info.splice(idx, 1);
      this.$iHttp
        .delete("/api/shopping_car/user_address/", {
          address_id_list: [id]
        })
        .then(res => {
          this.$toast.success("删除成功!");
        });
    },
    go_add(item) {
      let data = "";
      if (item) {
        data =
          "?data=" +
          encodeURIComponent(
            JSON.stringify({
              area: item.area,
              city: item.city,
              default_status: item.default_status,
              description: item.description,
              name: item.name,
              phone: item.phone,
              province: item.province,
              address_id: item.id
            })
          );
      }else if(this.address_info.length == 0){
          data =
          "?data=" +
          encodeURIComponent(
            JSON.stringify({
              isFirstAdd: true
            })
          );
      }
      this.$router.push("/addressAdd" + data);
    }
  }
};
</script>
<style lang="scss" scoped>
.back {
  display: flex;
  justify-content: center;
  .back_inner {
    max-width: 800px;
    .top_white {
      position: fixed;
      top: 0;
      z-index: 99999;
      width: 100%;
      background-color: white;
      display: flex;
      align-items: center;
      height: 1.4rem;
      border-bottom: 1px solid #f5f5f5;
      .van-image {
        width: 0.18rem;
        height: 0.34rem;
        margin-left: 0.5rem;
      }
      font-size: 17px;
      font-weight: 500;
      .top_back {
        display: flex;
        align-items: center;
      }
    }
    .address_slot {
      .address_left {
        margin: 0 15px;
        height: 80px;
        .address_first {
          display: flex;
          align-items: center;
          margin: 10px 0;
          .second_phone {
            margin: 0 20px;
          }
          .default {
            font-size: 13px;
            color: #f5bd23;
          }
        }
        .address_second {
          display: flex;
          justify-content: space-between;

          .add_des {
            width: 85%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      .delete-button {
        height: 80px;
      }
    }
    .go_it_outer {
      position: fixed;
      background-color: white;
      border-top: 1px solid #f5f5f5;
      z-index: 1;
      bottom: 0;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      .go_it {
        background-color: black;
        color: white;
        height: 1rem;
        width: 3rem;
        border-radius: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
      }
    }
  }
}
</style>